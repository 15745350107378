import React, { useMemo, useCallback, useState, useEffect } from 'react';
import Image from 'next/image';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { PotionItem, PotionPina, PotionDetail,
  PotionDetailResponse, fetchPotionDetail } from '@/apis/potion';
import { useBagList } from '@/apis/bag';
import { initInvoice } from '@telegram-apps/sdk';
import { fetchGetGameItem, EventId, useGameItemPackages } from '@/apis/game';
import { getPaymentLink } from '@/apis/payment';
import { ShowPromiseResult } from '@/types/adsgram';
import { useToast } from '@/components/ui/use-toast';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import useUserStatus from '@/hooks/useUserStatus';
import { isAdPlayingAtom } from '@/store/runtime';
import formatNumber from '@/utils/number/formatter';
import { formatMoney } from '@/utils/decimal/formatMoney';
import { track } from '@/utils/thinkdata/track';

const USDT = ({ children }) => {
  return (
    <>
      <Image className="mr-1 w-[20px] h-[20px]" alt="usdt" src={getPublicAsset('/images/bag/usdt.png')} width={24} height={24} />
      <span className="text-sm"> { children } </span>
    </>
  );
};

export const LevelTag = ({ level }) => {
  if (level < 4) {
    return (
      <div className="rounded-br-xl text-[12px] text-[#0E6900] bg-[#8BD580] border-1 border-[#0E6900]">
        lv{level}
      </div>
    );
  } else {
    return (
      <div className="rounded-br-xl text-[12px] text-[#07568A] bg-[#81C7EB] border-1 border-[#07568A]">
        lv{level}
      </div>
    );
  }
}

export const PinaCard = ({ pina }: { pina: PotionPina }) => {
  const bagList = useBagList();
  const pinaLogo = useMemo(() => {
    const record = bagList?.data?.data?.bananaRecordList?.find(r => r.name === pina.name);
    return record?.logo ? record.logo : "/images/pina/PinaManiaPlaceholder.png";
  }, [bagList]);

  return (
    <div className="relative flex bg-[#FFF4DA] px-1 py-2 border-2 border-[#C8AA73] justify-center items-center overflow-hidden rounded-xl">
      <div className="absolute text-center uppercase left-0 top-0 w-[32px] h-[12px]">
        <LevelTag level={pina.level} />
      </div>
      { pinaLogo ? <Image className="w-[48px] h-[48px] mr-1" src={getPublicAsset(pinaLogo)} alt={pina.name} width={48} height={48} /> : null }
      <div className="flex flex-col flex-1">
        <div className="text-sm"> {pina?.name} </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-xs">
            <span className="text-[#333]"> Prob: </span>
            <span className="text-white stroke-black">
              {`${formatNumber(pina?.prob * 100, { precision: 2 })}%`}
            </span>
          </div>
          <div className="text-xs">
            <span className="text-[#333]"> ServerFi: </span>
            <span className="text-white stroke-black">
              {pina?.serverFi}
            </span>
          </div>
        </div>
      </div>
      <div className={cn("flex flex-row px-1 w-[64px] h-[32px] justify-center items-center rounded-sm ml-2", pina.level < 4 ? "bg-white" : "bg-[#F9DC8E]")}>
        <USDT> {pina?.price} </USDT>
      </div>
    </div>
  );
};

type PotionDetailDialogProps = {
  show: boolean,
  onClose: () => void,
  potion: PotionItem,
  potionDetail: PotionDetail,
  potionPinas: PotionPina[],
};

export const PotionDetailDialog: React.FC<PotionDetailDialogProps> = ({
  show,
  onClose,
  potion,
  potionDetail,
  potionPinas
}) => {
  return (
    <AlertDialog open={show}>
      <AlertDialogContent
        enable3DBorder
        shadowColor="#EE9B40"
        className={cn(
          'relative flex flex-col bg-[#FFF4DA] !p-0 border-none outline-none shadow-none font-comicbd'
        )}
      >
        <BaseInteractive onInteractiveClick={onClose}>
          <div className="closeButton absolute top-[-18px] right-[-18px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
            <Image
              fill
              src={getPublicAsset('/images/lobby/close.png')}
              alt="close"
            />
          </div>
        </BaseInteractive>
        <AlertDialogTitle className="flex justify-center items-center bg-[#F6C334] text-white font-comicbd border-b-[2px] border-black w-[100%] h-[48px]">
          <div className="flex justify-center items-center bg-[#8BD580] text-base text-[#0E6900] rounded-xl w-[48px] h-[32px] uppercase mr-1">
            {`lv${potion?.potionId}`}
          </div>
          <div className="text-white stroke-2 stroke-black"> {potion?.name} </div>
        </AlertDialogTitle>
        <div className="p-2">
          <div className="relative flex flex-col bg-[#FFF4DA]">
            <div className="flex flex-row justify-between items-center p-4 bg-[#DDC26E30] border-[#745D2230] border-2 rounded-xl">
              <div className="flex justify-center items-center">
                <span className="mr-1"> Price: </span>
                <USDT> {formatMoney(Number(potion?.usdt) / Number(potion?.itemCount))} </USDT>
              </div>
              <div className="flex">
                <span className="mr-1"> Pina Rate Prob: </span>
                <span>
                  {`${formatNumber(potionDetail?.pinaRareProb * 100, { precision: 2 })}%`}
                </span>
              </div>
            </div>
          </div>
          <div className="text-2xl text-white text-center stroke-black mt-2 mb-2">
            Possible Pina Rewards
          </div>
          <div className="h-[320px] bg-[#DDC26E30] p-2 space-y-2 rounded-2xl overflow-y-scroll scrollbar-hide">
            {
              potionPinas?.map((pina) => {
                return (
                  <PinaCard
                    key={pina.name}
                    pina={pina}
                  />
                );
              })
            }
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
