import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { Button } from '@/components/ui/button';
import { isCopiedEasterEggPassAtom } from '@/store/runtime';
import { getTelegramMiniAppUrl, getEasterEggShareText } from '@/utils/shareLinks';
import { useAtom } from 'jotai';
import { useClipboard } from '@/hooks/useClipboard';
import { useToast } from '@/components/ui/use-toast';
import { initUtils } from '@telegram-apps/sdk';
import { useEasterEggPassCountdown } from './useEasterEggPassCountdown';

export const EasterEggPass = ({
  eggInfo,
  bgColor = '#574c5b',
}: {
  eggInfo: any;
  bgColor?: string;
  countdownStr?: string;
}) => {
  const { toast } = useToast();
  const { copyToClipboard } = useClipboard();
  const [, setIsCopiedEasterEggPass] = useAtom(isCopiedEasterEggPassAtom);
  const { countdown, countdownStr } = useEasterEggPassCountdown(
    eggInfo?.expireTime,
  );
  const shareText = [
    getTelegramMiniAppUrl('t', eggInfo.pass),
    getEasterEggShareText(countdownStr, eggInfo.pass),
  ].join('\n');

  return eggInfo?.pass ? (
    <div className="relative w-full flex flex-col">
      <div
        className={`relative w-full flex flex-row justify-between items-stretch h-[40px] rounded-[8px] bg-[${bgColor}] p-0 text-white`}
      >
        <span className="text-2xl ml-1 self-center"> {eggInfo?.pass} </span>
        <span className="text-xs mr-[40px] self-center">
          {' '}
          Expires: {countdown}s{' '}
        </span>
        <Button
          className="absolute w-[36px] h-[36px] top-0 right-0 mr-0 bg-[#cbe513]"
          shadowColor="#737b31"
          onClick={async () => {
            try {
              setIsCopiedEasterEggPass(true);
              await copyToClipboard(shareText);
              toast({
                description: `Copied ${shareText}`,
                className: "background: '#000', color: '#c4d719'",
              });
            } catch (err) {
              toast({
                description: 'Oops! Copy failed! Please enter your cipher.🙏',
                duration: 3000,
              });
            }
          }}
        >
          <Image
            className="w-[20px] h-[20px]"
            width={56}
            height={56}
            src={getPublicAsset('/images/lobby/copy.png')}
            alt="copy"
          />
        </Button>
      </div>
    </div>
  ) : null;
};
