export function formatLargeNumber(number: number | string) {
  const num = typeof number === 'string' ? parseFloat(number) : number;

  const units = [
    '', // 个位数
    'K', // 千 (kilo): 1,000
    'M', // 百万 (million): 1,000,000
    'B', // 十亿 (billion): 1,000,000,000
    'T', // 万亿 (trillion): 1,000,000,000,000
    'Q', // 千万亿 (quadrillion): 1,000,000,000,000,000
  ];

  // 如果数字小于 100,000,直接返回
  if (num < 100000) {
    return num.toString();
  }

  const unitIndex = Math.floor(Math.log10(num) / 3);

  const scaledNum = num / Math.pow(1000, unitIndex);

  // 保留两位小数
  const formattedNum = scaledNum.toFixed(2);

  // 如果小数部分都是0，则去掉小数部分
  const finalNum = formattedNum.replace(/\.00$/, '');

  return finalNum + units[unitIndex];
}
