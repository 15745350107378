export const SHARE_TEXT = [
  '',
  '🫰Join Pina Mania, No Tap 😈, The First ServerFi Game 🌎, Where Fun Meets Fortune! 💰🚀',
  '',
  '💸 1 ticket and +10k Pina Coins as a first-time gift',
  '',
  '🔥 3 tickets and +50k Pina Coins if you have Telegram Premium',
].join('\n');

export const getEasterEggShareText = (
  countdownStr: string,
  easterEggCode?: string,
) => {
  return [
    '',
    `🔥Right Now, Use My Unique Easter Egg Cipher Link To Claim A Rare Pina Reward 🍍🎉`,
    ' ',
    `⏳Expires ${countdownStr} `,
    ' ',
    '🫰Join Pina Mania, No Tap 😈, The First ServerFi Game 🌎, Where Fun Meets Fortune! 💰🚀',
  ].join('\n');
};

// deprecated
export const getGameUrl = (param: string, prefix: string = 'pina') => {
  if (!process.env.NEXT_PUBLIC_TELEGRAM_DIRECT_LINK) {
    throw new Error('Not found NEXT_PUBLIC_TELEGRAM_DIRECT_LINK in env');
  }
  return `${process.env.NEXT_PUBLIC_TELEGRAM_DIRECT_LINK}?startapp=${prefix}${param}`;
};

export const telegramForwardLink = (tgId: string, shareText?: string) => {
  return `https://t.me/share/url?url=${encodeURIComponent(getGameUrl(tgId))}&text=${encodeURIComponent(shareText || SHARE_TEXT)}`;
};

// ---------------------------- division ----------------------------
export const getShareLink = (prefix, code) => {
  return `${process.env.NEXT_PUBLIC_TELEGRAM_BOT}?start=${prefix}${code}`;
};

export const telegramShare = ({
  link,
  shareText,
}: {
  link?: string;
  shareText?: string;
}) => {
  const l = link || process.env.NEXT_PUBLIC_TELEGRAM_CHANNEL;
  const text = shareText || SHARE_TEXT;
  return `https://t.me/share/url?url=${encodeURIComponent(l)}&text=${encodeURIComponent(text)}`;
};

export const xShare = (text) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
};

export const getTelegramMiniAppUrl = (prefix, code) => {
  return `${process.env.NEXT_PUBLIC_TELEGRAM_DIRECT_LINK}?startapp=${prefix}${code}`;
};
