import React from 'react';
import Image from 'next/image';
import { useClipboard } from '@/hooks/useClipboard';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { userInfoAtom } from '@/store/userInfo';
import { Button } from '@/components/ui/button';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { useToast } from '@/components/ui/use-toast';
import { useAtomValue } from 'jotai';
import { cn } from '@/lib/utils';
import {
  telegramShare,
  xShare,
  getShareLink,
  getTelegramMiniAppUrl,
} from '@/utils/shareLinks';
import { SHARE_TEXT, getEasterEggShareText } from '@/utils/shareLinks';
import { track } from '@/utils/thinkdata/track';

type SocialPlatform = 'facebook' | 'telegram' | 'x' | 'youtube';

interface SocialIconProps {
  platforms?: SocialPlatform;
  size?: number;
  shareText?: string;
  className?: string;
  shareUrlOptions?: object;
  easterEggCode?: string;
  dividerColor?: string;
  countdownStr?: string;
}

const defaultPlatforms = ['telegram', 'x', 'youtube'];

const SocialIcon: React.FC<SocialIconProps> = ({
  platforms,
  size = 24,
  className,
  easterEggCode = '',
  dividerColor = '#000',
  countdownStr = '',
}) => {
  const { toast } = useToast();
  const { copyToClipboard } = useClipboard();
  const userInfo = useAtomValue(userInfoAtom);
  const socialPlatformMap = {
    telegram: {
      iconPath: getPublicAsset('/images/easter-egg/telegram.png'),
      officialUrl: process.env.NEXT_PUBLIC_TELEGRAM_CHANNEL,
      getShareUrl: () => {
        if (easterEggCode) {
          return telegramShare({
            link: getTelegramMiniAppUrl('t', easterEggCode),
            shareText: getEasterEggShareText(countdownStr, easterEggCode),
          });
        }
        // return telegramShare({
        //   link: getShareLink('pina', userInfo.tgId),
        //   shareText: SHARE_TEXT,
        // });
      },
    },
    x: {
      iconPath: getPublicAsset('/images/easter-egg/x.png'),
      officialUrl: process.env.NEXT_PUBLIC_X_LINK,
      getShareUrl: () => {
        if (easterEggCode) {
          return xShare(
            [
              `https://t.pinamania.com/${easterEggCode}`,
              getEasterEggShareText(countdownStr, easterEggCode),
              '',
              '#PinaMania #Ton #SeverFi #EasterEgg #Cipher',
            ].join('\n'),
          );
        }
        // return xShare([getShareLink('pina', userInfo.tgId), SHARE_TEXT].join('\n'));
      },
    },
    youtube: {
      iconPath: getPublicAsset('/images/easter-egg/youtube.png'),
      officialUrl: 'https://youtube.com/@pinamania',
      getShareUrl: () => {
        return 'https://youtube.com/@pinamania';
      },
    },
    facebook: {
      iconPath: getPublicAsset('/images/easter-egg/facebook.png'),
      officialUrl: 'https://www.facebook.com',
      getShareUrl: () => {
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://t.me/PinaManiaBot')}`;
      },
    },
  };

  const handleIconClick = (url, platform) => {
    // event.preventDefault();
    track('easterEgg_share', {
      type: platform,
    });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCopyLink = async () => {
    let text;
    if (easterEggCode) {
      text = [
        `https://t.pinamania.com/${easterEggCode}`,
        getEasterEggShareText(countdownStr, easterEggCode),
      ].join('\n');
    } else {
      text = getShareLink('pina', userInfo.tgId);
    }
    await copyToClipboard(text);
    toast({
      description: `Copied ${text}`,
      className: 'bg-[#000] text-[#c4d719]',
    });
  };

  return (
    <div className={cn('flex w-full justify-evenly items-center', className)}>
      {defaultPlatforms.map((platform) => {
        const { officialUrl, getShareUrl } = socialPlatformMap[platform];
        let url = easterEggCode ? getShareUrl() : officialUrl;
        return (
          <BaseInteractive
            key={platform}
            onInteractiveClick={() => handleIconClick(url, platform)}
          >
            <Image
              src={socialPlatformMap[platform].iconPath}
              alt={`${platform} icon`}
              width={size}
              height={size}
            />
          </BaseInteractive>
        );
      })}
      {easterEggCode ? (
        <>
          <div
            className={cn(
              `w-[1px] h-[${size}px] ml-1 mr-1`,
              `bg-[${dividerColor}]`,
            )}
          />
          <BaseInteractive onInteractiveClick={() => handleCopyLink()}>
            <Image
              src={getPublicAsset('/images/easter-egg/link.png')}
              alt={`copy link icon`}
              width={size}
              height={size}
            />
          </BaseInteractive>
        </>
      ) : null}
    </div>
  );
};

export default SocialIcon;
