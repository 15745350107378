import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

import ReactConfetti, { Props } from 'react-confetti';

export interface passedProps extends Props {
  width?: number;
  height?: number;
  gravity?: number;
}

export default React.forwardRef(
  (passedProps: passedProps, ref: React.MutableRefObject<any>) => {
    const { gravity } = passedProps;
    const { width: propWidth, height: propHeight } = useWindowSize();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const width = propWidth || windowWidth;
    const height = propHeight || windowHeight;

    return (
      <ReactConfetti
        width={width}
        height={height}
        gravity={gravity}
        {...passedProps}
        ref={ref}
      />
    );
  },
);
