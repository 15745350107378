'use client';

import react, { useState, useEffect } from 'react';
import { initInvoice } from '@telegram-apps/sdk';
import Image from 'next/image';
import { useAtom } from 'jotai';
import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useGameItemPackages } from '@/apis/game';
import { getPaymentLink } from '@/apis/payment';
import { useToast } from '@/components/ui/use-toast';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { cn } from '@/lib/utils';
import useMiniAppColor from '@/hooks/useMiniAppColor';
import useUserStatus from '@/hooks/useUserStatus';
import useAppStatus from '@/hooks/useAppStatus';
import useJettonTransfer from '@/hooks/useJettonTransfer';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { Button } from '@/components/ui/button';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { LoadingIcon } from '@/components/ui/loading';
import { ClassicPack, SalesRack } from '@/components/ui/SalesRack';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { TON_USDT, PINA_LITTLE_WALLET } from '@/constants/payment'
import { isProduction, isLocalDev } from '@/utils/checkRuntime';
import { useUtils } from '@telegram-apps/sdk-react';
import { isWalletModalOpenAtom } from '@/store/runtime';
import { pageView, track } from '@/utils/thinkdata/track';

const SendTransactionSuccessDialog = ({ txHash, onClose, ...props }) => {
  const utils = useUtils();
  const { toast } = useToast();
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  return (
    <AlertDialog {...props}>
      <AlertDialogContent
        className="relative flex flex-col w-10/12 p-0 bg-[#FFF4DA] font-comicbd justify-center items-center"
        shadowColor="#EE9B40"
        enable3DBorder
      >
        <BaseInteractive onInteractiveClick={onClose}>
          <div className="closeButton absolute w-[32px] h-[32px] right-[-16px] top-[-16px] active:translate-x-0.5 active:translate-y-0.5 z-40">
            <Image fill sizes="80vw" src={getPublicAsset('/images/lobby/close.png')} alt="close" />
          </div>
        </BaseInteractive>
        <AlertDialogTitle className="text-xl w-full bg-[#F6C334] p-4 text-center text-white stroke-black border-b-1 border-black">
          Thank your for your purchase!
        </AlertDialogTitle>
        <div className="px-2">
          <div className="w-full text-center text-md">
            <p> We are processing your order. </p>
            <p> If you have any questions, feel free to contact our customer support team at any time.  </p>
          </div>
          <div className="flex flex-row w-full rounded-md bg-[#F5E5BA] justify-between items-center text-xs">
            {
              txHash ? (
                <>
                  <div className="w-[48px] text-center"> TX: </div>
                  <p className="text-[#56B007]">
                    {txHash.slice(0, 8)}...{txHash.slice(txHash.length-8, txHash.length)}
                  </p>
                  <Button
                    className="flex bg-[#c4d719] w-[32px] h-[32px] justify-center items-center"
                    shadowColor="#c4d719"
                    onClick={async () => {
                      await copyToClipboard(txHash);
                      toast({
                        description: txHash,
                        duration: 3000,
                      });
                    }}>
                    <Image
                      width={32}
                      height={32}
                      src={getPublicAsset('/images/lobby/copy.png')}
                      alt="copy"
                    />
                  </Button>
                </>
              ) : null
            }
          </div>
        </div>
        <Button
          className="flex w-[200px] px-2 mb-2 mb-6 bg-[#ffcb38] justify-center items-center text-white stroke-black"
          shadowColor="#a37f35"
          onClick={() => {
            utils.openLink(`https://tonviewer.com/transaction/${txHash}`);
          }}>
          View On Tonviewer
        </Button>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const PaymentButton = ({ clickHandler, icon, amount, text }) => {
  const [pending, setPending] = useState(false);
  return (
    <Button
      className="flex justify-center items-center bg-[#C9D54B] h-[56px]"
      shadowColor="#808A3F"
      onClick={async () => {
        try {
          setPending(true);
          await clickHandler();
        } finally {
          setPending(false);
        }
      }}
    >
      {
        pending ? (
          <LoadingIcon />
        ) : (
          <>
            <Image
              className="flex justify-center items-center w-[24px] h-[24px] mr-1"
              src={getPublicAsset(icon)}
              alt={text}
              width={48}
              height={48}
            />
            <span> {amount} </span>
            <span className="ml-1"> {text} </span>
          </>
        )
      }
    </Button>
  );
};

const PaymentDialog = ({
  open,
  closeHandler,
  paymentSuccess,
  PackagePicture,
  packageName,
  payId,
  star,
  usdt,
}) => {
  const t = useTranslations();
  const invoice = initInvoice();
  const [txHash, setTxHash] = useState('');
  const [openTxSuccessDialog, setOpenTxSuccessDialog] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useAtom(isWalletModalOpenAtom);
  const { toast } = useToast();
  const { data: userData, refetchUserStatus } = useUserStatus();
  const { userInfo } = userData;
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const { transfer } = useJettonTransfer();
  const { modal } = tonConnectUI;

  const setWalletOpenHandler = function(event) {
    setIsWalletModalOpen(true);
    event.target.removeEventListener(event.type, setWalletOpenHandler);
  };

  const setWalletCloseHandler = function(event) {
    setIsWalletModalOpen(false);
    event.target.removeEventListener(event.type, setWalletCloseHandler);
  };

  modal.onStateChange(state => {
    setIsWalletModalOpen(state.status === "opened");
  });

  useEffect(() => {
    window?.addEventListener('ton-connect-ui-transaction-sent-for-signature', setWalletOpenHandler);
    window?.addEventListener('ton-connect-ui-transaction-signed', setWalletCloseHandler);
    window?.addEventListener('ton-connect-ui-transaction-signing-failed', setWalletCloseHandler);
  }, []);

  const buyWithTonUsdt = async () => {
    try {
      if (!tonConnectUI.connected) {
        return tonConnectUI.openModal();
      }
      track('order_buy', {
        order_id: payId,
        scene: 'store',
      });
      const { success, txHash, error } = await transfer({
        jettonMasterAddress: TON_USDT,
        toAddress: PINA_LITTLE_WALLET,
        amount: usdt,
        // IMPORTANT!!!
        // deciamls for usdt
        // https://tonviewer.com/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs?section=jetton
        decimals: 6,
        memo: `${userInfo.tgId}+${payId}`,
      });
      setTxHash(txHash);
      if (success) {
        setOpenTxSuccessDialog(success);
      } else {
        throw new Error(error);
      }
    } catch(err) {
      if (err.message.includes('User rejects') ||
          err.message.includes('UserRejectserr') ||
          err.message.includes('User declined') ||
          err.message.includes('Transaction was not sent')) {
        // Ingore user cancel the transaction
      } else {
        toast({
          description: 'Oops! Something went wrong, try again later.🙏',
          duration: 3000,
        });
      }
      console.error(err);
      track('order_buy_err', {
        order_id: payId,
      });
      Sentry.captureException(err);
    } finally {
    }
  };

  const buyWithStar = async () => {
    try {
      track('order_buy', {
        order_id: payId,
        scene: 'store',
      });
      const { link } = await getPaymentLink({ id: payId });
      invoice.open(link, 'url').then((status) => {
        if (status === 'cancelled') return;
        if (status === 'paid') {
          toast({
            description: 'Thank you for your patronage.',
            duration: 3000,
          });
          track('order_finish', {
            order_id: payId,
            // todo
          });
          // update user game status
          refetchUserStatus();
          if (paymentSuccess) {
            paymentSuccess();
          }
        }
        if (status === 'failed') {
          track('order_buy_failed', {
            order_id: payId,
            // todo
          });
          throw new Error('payment failed');
        }
        console.log(status);
      });
    } catch (err) {
      toast({
        description: 'Oops! Something went wrong, try again later.🙏',
        duration: 3000,
      });
      console.error(err);
      track('order_buy_err', {
        order_id: payId,
      });
      Sentry.captureException(err);
    } finally {
    }
  };

  return (
    <>
      <SendTransactionSuccessDialog
        onClose={() => setOpenTxSuccessDialog(false)}
        onOpenChange={setOpenTxSuccessDialog}
        txHash={txHash}
        open={openTxSuccessDialog}
      />
      <AlertDialog open={open}>
        <AlertDialogContent
          enable3DBorder
          shadowColor="#EE9B40"
          className={cn(
            'relative flex flex-col bg-[#FFF4DA] !p-0 border-none outline-none shadow-none font-comicbd'
          )}
        >
          <BaseInteractive onInteractiveClick={closeHandler}>
            <div className="closeButton absolute w-[32px] h-[32px] right-[-16px] top-[-16px] active:translate-x-0.5 active:translate-y-0.5 z-40">
              <Image fill sizes="80vw" src={getPublicAsset('/images/lobby/close.png')} alt="close" />
            </div>
          </BaseInteractive>
          <AlertDialogTitle className="flex justify-center items-center bg-[#F6C334] text-white stroke-black font-comicbd border-b-[2px] border-black w-[100%] h-[48px]">
            Purchase
          </AlertDialogTitle>
          <div className="flex flex-col justify-center items-center">
            {PackagePicture}
            {
              // <div className="relative w-[64px] h-[64px]">
              //   <Image className="absolute left-0 top-0 w-[64px] h-[64px]" src={getPublicAsset(picture)} alt={packageName} width={56} height={56} />
              //   <span className="absolute bottom-0 px-2 w-[64px] text-center text-sm text-white stroke-black">
              //   </span>
              // </div>
            }
            <span className="mt-2 px-2 py-2 bg-white text-lg text-black bg-white rounded-2xl"> {packageName} </span>
            <div className="mt-2 text-2xl text-[#FADC60] stroke-black"> {'≈$'}{usdt} </div>
          </div>
          <div className="flex flex-col justify-center items-center p-2">
            <div className="grid grid-cols-1 grid-rows-2 gap-2 w-full text-base mt-2">
              <PaymentButton
                icon="/images/lobby/telegram-star.svg"
                clickHandler={buyWithStar}
                amount={star}
                text="STAR"
              />
              <PaymentButton
                icon="/images/bag/usdt.png"
                clickHandler={buyWithTonUsdt}
                amount={usdt}
                text="USDT"
              />
              {
                tonConnectUI.connected ? (
                  <div className="flex flex-row justify-center items-center text-xs">
                    <BaseInteractive onInteractiveClick={async () => {
                      await copyToClipboard(userFriendlyAddress);
                      toast({
                        description: userFriendlyAddress,
                        duration: 3000,
                      });
                    }}>
                      <div className="flex flex-row text-[#56B007] bg-[#DDC26E] rounded-md p-2 mb-2 mr-2">
                        {userFriendlyAddress?.slice(0,6)}...{userFriendlyAddress?.slice(userFriendlyAddress.length - 6, userFriendlyAddress.length)}
                      </div>
                    </BaseInteractive>
                    <Button
                      className="flex w-[120px] p-1 bg-[#ffcb38] text-white stroke-black justify-center items-center"
                      shadowColor="#a37f35"
                      onClick={() => {
                        tonConnectUI.disconnect();
                      }}>
                      Disconnect
                    </Button>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default PaymentDialog;
