import { useAtom } from 'jotai';
import { userEasterEggInfoAtom } from '@/store/userInfo';
import { useEasterEggPassCountdown } from './useEasterEggPassCountdown';
import { EasterEggPass } from './EasterEggPass';
import SocialIcon from '@/components/ui/SocialIcon';
import { cn } from '@/lib/utils';

export const EasterEggShare = ({
  className = 'bg-[#fceced]',
  passBgColor,
  socialIconDividerColor,
}: {
  className?: string;
  passBgColor?: string;
  socialIconDividerColor?: string;
}) => {
  const [eggInfo] = useAtom(userEasterEggInfoAtom);
  const { countdownStr } = useEasterEggPassCountdown(eggInfo?.expireTime);
  const text = eggInfo?.pass
    ? 'Share your unique cipher link to earn rewards per claim!'
    : 'Discover the cipher in our community';
  return (
    <div
      className={cn(
        'flex flex-col gap-y-2 justify-center items-center w-full p-2 mt-4 !mx-0 text-black text-sm text-center rounded-[16px] border-2 border-black',
        className,
      )}
    >
      <EasterEggPass bgColor={passBgColor} eggInfo={eggInfo} />
      <p>{text}</p>
      <SocialIcon
        dividerColor={socialIconDividerColor}
        size={40}
        easterEggCode={eggInfo?.pass}
        countdownStr={countdownStr}
      />
    </div>
  );
};
