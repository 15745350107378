import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { PotionItem, PotionPina, PotionDetail,
  PotionDetailResponse, fetchPotionDetail } from '@/apis/potion';
import { useBagList } from '@/apis/bag';
import { initInvoice } from '@telegram-apps/sdk';
import { fetchGetGameItem, EventId, useGameItemPackages } from '@/apis/game';
import { getPaymentLink } from '@/apis/payment';
import { ShowPromiseResult } from '@/types/adsgram';
import { useToast } from '@/components/ui/use-toast';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import PaymentDialog from '@/components/ui/PaymentDialog';
import useUserStatus from '@/hooks/useUserStatus';
import { track } from '@/utils/thinkdata/track';

type BuyPotionDialogProps = {
  show: boolean,
  closeHandler: () => void,
  paymentSuccess: () => void,
  potion: PotionItem,
  PackagePicture: React.ReactNode,
};

export const BuyPotionDialog: React.FC<BuyPotionDialogProps> = ({
  show,
  closeHandler,
  paymentSuccess,
  potion,
  PackagePicture,
}) => {
  return (
    <PaymentDialog
      open={show}
      closeHandler={closeHandler}
      paymentSuccess={paymentSuccess}
      PackagePicture={PackagePicture}
      packageName={potion?.name}
      payId={potion?.payId}
      star={potion?.star}
      usdt={potion?.usdt}
    />
  );
};
