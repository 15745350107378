import { Button } from '@/components/ui/button';
import { getItemCount } from '@/app/[lng]/game/utils';
import { get } from 'lodash-es';
import useGameItemsMap from '@/app/[lng]/game/hooks/useGameItemsMap';
import useImpactOccurred from '@/hooks/useVibration';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { userInfoAtom, userGameStatus } from '@/store/userInfo';
import { currentPotionAtom } from '@/store/runtime';
import { useAtom } from 'jotai';
import { Subtract } from '@/utils/decimal/operations';
import { useMutation } from '@tanstack/react-query';
import { EventId, gameStart, type UserMetaModel } from '@/apis/game';
import { useRouter } from 'next/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { useToast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { UserGameStatusDTO } from '@/apis/login';
import { fetchPotionDetail, PotionDetailResponse } from '@/apis/potion';
import { DifficultyDisplay } from '@/components/lobby/DifficultyDisplay';
import { getPublicAsset } from '@/utils/getPublicAsset';
import Image from 'next/image';
import { TicketDialog } from '@/app/[lng]/game/components/TicketDialog';
import formatMoney from '@/utils/decimal/formatMoney';
import { formatLargeNumber } from '@/utils/number/formatLargeNumber';
import formatNumber from '@/utils/number/formatter';
import StartGameUsePotion from '@/components/Potion/StartGameUsePotion';
import { cn } from '@/lib/utils';
import { track } from '@/utils/thinkdata/track';

const usePlayGame = () => {
  const [isContinueOpen, setIsContinueOpen] = useState(false);
  const gameItemsMap = useGameItemsMap();
  const [impactOccurred] = useImpactOccurred('heavy');
  const [userGame, setUserGameStatus] = useAtom(userGameStatus);
  const [isGamePageLoading, setIsGamePageLoading] = useState(false);
  const { journey } = userGame;
  const locale = useLocale();
  const t = useTranslations();
  const canFreePlay: boolean = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Number(Subtract(freeTimes, playTimes)) > 0;
  }, [userGame]);
  const lastFreeTimes = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Math.max(Subtract(freeTimes, playTimes), 0);
  }, [userGame]);
  const { toast } = useToast();
  const ticket = getItemCount(get(gameItemsMap, 'ticket.haveCount', 0));
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [currentPotion] = useAtom(currentPotionAtom);
  const handleGameStart = () => {
    impactOccurred();
    if (!canFreePlay && ticket === '0') {
      setTicketDialogShow(true);
      return;
    }
    setIsContinueOpen(true);
    console.log(isContinueOpen);
  };
  const handleGameNextlevel = (callback?: (data: UserMetaModel) => void) => {
    goToGame(
      { eventId: EventId.start, potionId: currentPotion?.potionId },
      {
        onSuccess: (data) => {
          callback?.(data);
        },
      },
    );
  };
  const hasSufficientBalance = useMemo(
    () => Number(journey?.nextPrice) < Number(userInfo?.gold),
    [journey, userInfo],
  );
  const canEnterNextLevel = useMemo(() => {
    return canFreePlay || (ticket !== '0' && hasSufficientBalance);
  }, [canFreePlay, ticket, hasSufficientBalance]);

  const handleContinueGameStart = () => {
    setTicketDialogShow(false);
    setIsContinueOpen(true);
  };
  const { mutate: goToGame, isPending: isFetchGameStartLoading } = useMutation({
    mutationFn: gameStart,
    onSuccess: (data) => {
      setIsGamePageLoading(true);
      track('challenge_start', {
        isFreePlay: canFreePlay,
        ticket: getItemCount(get(gameItemsMap, 'ticket.haveCount', 0)),
        task_id: journey.currentLevel,
        cost_coin: journey?.nextPrice,
      });
      setUserInfo({
        ...data.userModel,
      });
    },
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
  });
  const [ticketDialogShow, setTicketDialogShow] = useState(false);
  const isGameLoading = useMemo(() => {
    return isFetchGameStartLoading || isGamePageLoading;
  }, [isFetchGameStartLoading, isGamePageLoading]);

  const linkRef = useRef<HTMLAnchorElement>(null);
  const goToGamePage = () => {
    linkRef.current?.click();
  };

  const PlayGameButton = (
    <div>
      <Button
        onClick={handleGameStart}
        className={`${isGameLoading ? 'pointer-events-none ' : ''}block bg-[#E2F43E]  pt-[0.3rem] pb-[0.5rem] px-[30px] select-none   min-h-[3.5rem]`}
        shadowColor="#858F23"
      >
        <div className="flex-row justify-center items-center">
          <span
            className={
              'font-bold stroke-chocolate text-white font-mono text-xl stroke-black text-nowrap'
            }
          >
            {isGameLoading ? 'Starting...' : t('play-game')}
          </span>
          <div className="text-[#6e5c20] text-xs">
            <span className={'font-bold'}>
              {lastFreeTimes}/{userGame.playFreeTimes} {t('free-attempts')}
            </span>
          </div>
        </div>
      </Button>

      <ContinueDialog
        isOpen={isContinueOpen}
        onClose={() => setIsContinueOpen(false)}
        onContinue={() => {
          !isGameLoading &&
            goToGame(
              { eventId: EventId.start, potionId: currentPotion?.potionId },
              {
                onSuccess: () => {
                  goToGamePage();
                },
              },
            );
        }}
        journey={journey}
        userGame={userGame}
        userInfo={userInfo}
        hasSufficientBalance={hasSufficientBalance}
        isPending={isGameLoading}
        t={t}
        tickets={getItemCount(get(gameItemsMap, 'ticket.haveCount', 0))}
      />
      <TicketDialog
        show={ticketDialogShow}
        onClose={() => setTicketDialogShow(false)}
        handleContinueGameStart={handleContinueGameStart}
      />
      <LocalizedLink href={`/game`} prefetch={true} ref={linkRef} />
    </div>
  );

  return {
    PlayGameButton,
    handleGameStart,
    handleGameNextlevel,
    canEnterNextLevel,
  };
};

const ContinueDialog = ({
  isOpen,
  onClose,
  onContinue,
  journey,
  userGame,
  userInfo,
  isPending,
  tickets,
  t,
  hasSufficientBalance,
}: {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  journey: UserGameStatusDTO['journey'];
  userGame: any;
  userInfo: any;
  isPending: boolean;
  tickets: string;
  t: any;
  hasSufficientBalance: boolean;
}) => {
  const InfoItem = ({ label, value, valueClass = "", icon }) => (
    <div className="text-nowrap">
      <span className="text-right text-[#222] inline-block">{label}:</span>
      <span className="space-x-1 ml-1">
        {label === 'Balance' ? (
          <span className={cn("inline-block", valueClass)}>{formatLargeNumber(value)}</span>
        ) : (
          <span className={cn("inline-block", valueClass)}>{value}</span>
        )}
        {icon && (
          <Image
            className="inline-block align-middle"
            width={20}
            height={20}
            src={icon}
            alt="icon"
          />
        )}
      </span>
    </div>
  );

  const InfoSection = ({
    items,
    showTips = false,
    pinaRareProb,
  }: {
    items: Array<any>;
    showTips: boolean;
    pinaRareProb: string;
  }) => {
    const labelOrder = ['Cost', 'Balance', 'Difficulty', 'Reward'];
    const levelText =
      journey.currentLevel < 6 ? (
        <p>
          Level <span className={'text-[#FAD54A]'}>{journey.currentLevel}</span>{' '}
          now, <span className={'text-[#7FC272]'}>beat it to win Pina!</span>{' '}
        </p>
      ) : (
        <p className={'whitespace-nowrap'}>
          The last level, beat it to win{' '}
          <span className={'text-[#FF9900]'}>Rare Pina!</span>
        </p>
      );
    return (
      <div className={'flex flex-col bg-[#DDC26E30] rounded-xl py-3 px-3 text-[14px]'}>
        <div className="text-base stroke-black text-center font-bold mb-2 font-comicbd text-[16px]">
          {levelText}
        </div>
        <div
          className={
            'grid grid-cols-2 gap-y-1 w-full text-black flex flex-row justify-between'
          }
        >
          {
            labelOrder.map((o) => {
              const item = items.find(item => item.label === o);
              if (!item) return null;
              return (
                <InfoItem key={o} {...item} />
              );
            })
          }
        </div>
        <InfoItem
          key={"Pina Rare Prob"}
          label="Pina Rare Prob"
          valueClass="mt-1 text-[#FAD54A] stroke-black"
          value={pinaRareProb ? pinaRareProb : "pending..."}
          icon={null}
        />
      </div>
    );
  };
  const coinIcon = getPublicAsset('/images/lobby/coins.png');

  const gameInfo = [
    {
      label: t('message.difficulty'),
      value: (
        <DifficultyDisplay difficultyLevel={String(journey.currentLevel)} />
      ),
    },
    { label: t('message.balance'), value: userInfo.gold, icon: coinIcon },
    {
      label: t('message.cost'),
      value: formatMoney(journey?.nextPrice),
      icon: coinIcon,
    },
    {
      label: t('message.reward'),
      value: formatMoney(journey.nextRewards),
      icon: coinIcon,
    },
  ];
  const router = useRouter();
  const locale = useLocale();
  const [potion, setPotion] = useState(null);
  const [pinaRareProb, setPinaRareProb] = useState('');
  const [pinaRareProbPotion, setPinaRareProbPotion] = useState('');

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent
        enable3DBorder
        shadowColor="#EE9B40"
        className={
          'flex flex-col bg-[#FFF4DA] text-white !p-0 border-none outline-none shadow-none font-comicbd'
        }
      >
        <AlertDialogTitle className="w-auto bg-[#F6C334] py-2 border-b-2 border-black text-lg text-white stroke-black text-center">
          <span className={'text-xl leading-6'}>
            {t('message.trip', {
              index: journey?.index,
            })}
          </span>
        </AlertDialogTitle>
        <div className="px-3">
          <InfoSection items={gameInfo} showTips={true} pinaRareProb={pinaRareProb} />
          <div>
            <div className="flex flex-col text-base items-center justify-center text-white stroke-black my-2">
              <span> Use potion to boost Pina Rare Prob to </span>
              <span className="text-[#FAD54A] stroke-black">
                {pinaRareProbPotion ? pinaRareProbPotion : "pending..."}
              </span>
            </div>
            <StartGameUsePotion
              onPotionDetailResponse={(data) => {
                setPinaRareProb(`${formatNumber(data?.xprob * 100, { precision: 2 })}%`);
                setPinaRareProbPotion(`${formatNumber(data?.potionDetail.pinaRareProb * 100, { precision: 2 })}%`);
              }}
            />
          </div>
          <AlertDialogFooter className="!flex my-2">
            {hasSufficientBalance ? (
              <>
                <AlertDialogCancel className="" onClick={onClose}>
                  {t('cancel')}
                </AlertDialogCancel>
                <AlertDialogAction
                  className="bg-[#FFCB38]"
                  /* @ts-ignore */
                  shadowColor="#A37F35"
                  onClick={onContinue}
                >
                  {isPending ? 'Launching...' : 'Continue'}
                </AlertDialogAction>
              </>
            ) : (
              <AlertDialogAction
                className=""
                onClick={() => router.push(`${locale}/earn`)}
              >
                {t('need-more-pine-coin')}
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default usePlayGame;
