import React, { useState, useMemo, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useAtom } from 'jotai';
import { cn } from '@/lib/utils';
import { useDraggable } from "react-use-draggable-scroll";
import {
  PotionItem,
  PotionPina,
  PotionDetail,
  usePotionPackages,
  PotionDetailResponse,
  fetchPotionDetail,
  fetchUpdatePotion
} from '@/apis/potion';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { PotionDetailDialog, BuyPotionDialog  } from '@/components/Potion';
import { useToast } from '@/components/ui/use-toast';
import { useMutation } from '@tanstack/react-query';
import useUserStatus from '@/hooks/useUserStatus';
import { currentPotionAtom, emptyPotion } from '@/store/runtime';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { Button } from '@/components/ui/button';
import { LoadingIcon } from '@/components/ui/loading';

const Tab = ({ selected, text, onClick }) => {
  return (
    <BaseInteractive onInteractiveClick={onClick}>
      <div className={cn("px-2 py-1 border-2 border-black text-black cursor-pointer rounded-2xl",
                         selected ?
                           "bg-white" :
                           "bg-[#DDC26E] text-black opacity-30")}
      >
        { text }
      </div>
    </BaseInteractive>
  );
};

const PotionCard = ({
  selected,
  potion,
  poisoned,
  openPotionDetailHandler,
  openBuyPotionHandler,
  onClick
}: {
  selected: boolean;
  potion: PotionItem;
  poisoned: boolean;
  openPotionDetailHandler: () => void;
  openBuyPotionHandler: () => void;
  onClick: () => void;
}) => {
  const { toast } = useToast();
  const potionLogo = `/images/potions/lv${potion.potionId}.png`;
  const [currentPotion, setCurrentPotion] = useAtom(currentPotionAtom);
  const usePotionMutation = useMutation({
    mutationFn: (potionId:string|number) => {
      return Promise.resolve();
      // return fetchUpdatePotion({ potionId });
    },
    onSuccess: (data, potionId) => {
      if (poisoned) return;
      if (currentPotion.potionId === potionId.toString()) {
        setCurrentPotion(emptyPotion);
      } else {
        setCurrentPotion(potion);
      }
    },
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
  });

  return (
    <BaseInteractive onInteractiveClick={onClick}>
      <div className={cn("relative flex flex-row items-center rounded-xl p-2 border-2 border-[#C8AA73] text-black",
                         selected ? "bg-[#F9DC8E]" : "bg-[#FFF4DA]")}
      >
        <div className="absolute right-[-8px] top-[-8px] flex justify-center items-center px-1 h-[24px] text-sm border-1 bg-[#FFCC53] rounded-full">
          {potion.haveCount}
        </div>
        <div className="absolute left-[64px] bottom-[-4px] flex justify-center items-center px-1 h-[24px] text-sm border-1 bg-[#FFCC53] rounded-full">
          X{potion.itemCount}
        </div>
        <div className="relative w-[64px] h-[64px]">
          <Image
            className="w-[64px] h-[64px]"
            src={potionLogo}
            alt={potion.name}
            width={48} height={48}
          />
          <div className="absolute flex justify-center items-center text-xs text-white stroke-black w-[48px] h-[48px] left-0 top-[20px] uppercase">
            lv{potion.potionId}
          </div>
        </div>
        <div className="flex flex-1 text-wrap text-base">
          <div>
            <span>
              {potion.name}
            </span>
            <BaseInteractive onInteractiveClick={() => openPotionDetailHandler()}>
              <Image className="inline w-[18px] h-[18px] cursor-pointer ml-1" src="/images/potions/potion-detail.png" alt="potion detail" width={24} height={24} />
            </BaseInteractive>
          </div>
        </div>
        <div className="flex items-center w-auto text-sm">
          <Image className="w-[24px] h-[24px] mr-1" src="/images/bag/usdt.png" alt="usdt" width={24} height={24} />
          <div> {potion.usdt} </div>
        </div>
        <div className="flex w-[48px] justify-center items-center cursor-pointer ml-2">
          {
            Number(potion.haveCount) > 0 ? (
              currentPotion?.name === potion.name ? (
                <BaseInteractive onInteractiveClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  usePotionMutation.mutate(potion.potionId);
                }}>
                  <Image
                    className="w-[28px] h-[28px] border-[2px] border-black rounded-full"
                    src={getPublicAsset('/images/potions/correct.png')}
                    alt="use this potion"
                    width={64}
                    height={64}
                  />
                </BaseInteractive>
              ) : poisoned ? null : (
                <BaseInteractive onInteractiveClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  usePotionMutation.mutate(potion.potionId);
                }}>
                  <div className="flex justify-center items-center w-[48px] h-[32px] text-sm border-2 border-black bg-[#C4D719] rounded-3xl">
                    {
                      usePotionMutation.isPending ? (
                        <LoadingIcon />
                      ) : "Use"
                    }
                  </div>
                </BaseInteractive>
              )
            ) : (
              <BaseInteractive onInteractiveClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClick();
                openBuyPotionHandler();
              }}>
                <div className="flex justify-center items-center w-[48px] h-[32px] text-sm border-2 border-black bg-[#C4D719] rounded-3xl">
                  <Image className="w-[16px] h-[16px]" src="/images/potions/plus.svg" alt="plus" width={24} height={24} />
                </div>
              </BaseInteractive>
            )
          }
        </div>
      </div>
    </BaseInteractive>
  );
};

export const StartGameUsePotion = ({
  className = "",
  onPotionDetailResponse,
  ...props
}) => {
  const ref = useRef();
  const { toast } = useToast();
  const { events } = useDraggable(ref);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedPotion, setSelectedPotion] = useState(null);
  const [currentPotion, setCurrentPotion] = useAtom(currentPotionAtom);
  const [potionDetail, setPotionDetail] = useState(null);
  const [potionPinas, setPotionPinas] = useState(null);
  const [openPotionDetailDialog, setOpenPotionDetailDialog] = useState(false);
  const [openBuyPotionDialog, setOpenBuyPotionDialog] = useState(false);
  const { data: userStatus } = useUserStatus();
  const { data: potionPackages, refetch: reloadPotions } = usePotionPackages();
  const { gameStatus } = userStatus;
  const { journey: { journeyPotion }} = gameStatus;

  const getPotionMutation = useMutation({
    mutationFn: (potionId:string|number) =>
      fetchPotionDetail({ potionId }),
    gcTime: 2 * 60 * 1000,
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
    onSuccess: (data) => {
      setPotionDetail(data?.potionDetail);
      setPotionPinas(data?.potionPinas);
      onPotionDetailResponse(data);
    },
  });

  const potions = useMemo(() => {
    if (!potionPackages) return [];
    let p = [];
    Object.keys(potionPackages.map).map((key) => {
      p = p.concat(potionPackages.map[key]);
    });
    return p;
  }, [potionPackages]);

  const tabs = useMemo(() => {
    if (!potionPackages) return [];
    const r = Object.keys(potionPackages?.map);
    return r ? r : [];
  }, [potionPackages]);

  const potionList = useMemo(() => {
    if (!potionPackages || !selectedTab) return [];
    return potionPackages?.map[selectedTab];
  }, [selectedTab, potionPackages]);

  useEffect(() => {
    if (tabs[0]) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  useEffect(() => {
    if (selectedPotion?.potionId) {
      getPotionMutation.mutate(selectedPotion?.potionId)
    }
  }, [selectedPotion]);

  useEffect(() => {
    if (!journeyPotion) {
      setCurrentPotion(emptyPotion);
    } else {
      const p = potions.find(potion => {
        return potion.name === journeyPotion.name;
      });
      setCurrentPotion(p ? p : emptyPotion);
    }
  }, [journeyPotion, potions]);

  // we need request potion once to fetch the default pina grab number
  useEffect(() => {
    getPotionMutation.mutate(1);
  }, [])

  return (
    <>
      <div
        className={`flex flex-col font-comicbd ${className}`}
        {...props}
      >
        <div
          className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
          ref={ref}
          {...events}
        >
          {
            tabs.map((tab) => {
              return (
                <Tab
                  key={tab}
                  selected={tab === selectedTab}
                  text={tab}
                  onClick={() => setSelectedTab(tab)}
                />
              );
            })
          }
        </div>
        <div className="relative">
          {
            // getPotionMutation.isPending ? (
            //   <div className="absolute flex justify-center items-center stroke-black w-full h-[200px] left-0 bg-[#FFF4DA] opacity-90 z-10">
            //     Pending...
            //   </div>
            // ) : null
          }
          <div className="flex flex-col bg-[#DDC26E30] h-[270px] mt-2 mb-2 rounded-xl p-2 max-w-xl space-y-1 overflow-y-scroll scrollbar-hide">
            {
              potionList.map((potion) => {
                return (
                  <PotionCard
                    key={potion.name}
                    selected={false && potion === selectedPotion}
                    potion={potion}
                    poisoned={false && !!gameStatus.journey.journeyPotion}
                    openPotionDetailHandler={() => { setOpenPotionDetailDialog(true) }}
                    openBuyPotionHandler={() => { setOpenBuyPotionDialog(true)}}
                    onClick={() => setSelectedPotion(potion)}
                  />
                );
              })
            }
          </div>
        </div>
      </div>

      <PotionDetailDialog
        show={openPotionDetailDialog}
        onClose={() => setOpenPotionDetailDialog(false)}
        potion={selectedPotion}
        potionDetail={potionDetail}
        potionPinas={potionPinas}
      />

      <BuyPotionDialog
        show={openBuyPotionDialog}
        potion={selectedPotion}
        PackagePicture={(
          <div className="relative w-[64px] h-[64px]">
            <Image
              className="absolute left-0 top-0 w-[64px] h-[64px]"
              src={getPublicAsset(`/images/potions/lv${selectedPotion?.potionId}.png`)} 
              alt={selectedPotion?.name}
              width={56}
              height={56}
            />
            <span className="absolute bottom-0 px-2 w-[64px] text-center text-sm text-white stroke-black">
              +{selectedPotion?.itemCount}
            </span>
          </div>
        )}
        paymentSuccess={() => {
          reloadPotions();
        }}
        closeHandler={() => {
          setOpenBuyPotionDialog(false);
        }}
      />
    </>
  );
};

export default StartGameUsePotion;
