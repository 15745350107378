import { useState, useCallback } from 'react';
import { TonClient, Address, beginCell, toNano, Cell } from '@ton/ton';
import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import TonWeb from "tonweb";

interface TransferParams {
  jettonMasterAddress: string;
  toAddress: string;
  amount: string;
  decimals?: number;
  memo?: string;
}

interface TransferResult {
  success: boolean;
  txHash?: string;
  error?: string;
}

interface UseJettonTransfer {
  transfer: (params: TransferParams) => Promise<TransferResult>;
  isPending: boolean;
  error: string | null;
  lastTxHash: string | null;
}

export async function getJettonWalletAddress(client: TonWeb, ownerAddress: string, jettonMasterAddress: string): Promise<string> {
  // @ts-ignore
  const minter = new TonWeb.token.jetton.JettonMinter(client.provider, {
    address: new TonWeb.utils.Address(jettonMasterAddress),
  });
  const address = await minter.getJettonWalletAddress(new TonWeb.utils.Address(ownerAddress));
  return address.toString();
}

export async function initializeClient(network: 'testnet' | 'mainnet'): Promise<TonWeb> {
  if (process.env.NEXT_PUBLIC_TON_ENDPOINT) {
    return new TonWeb(new TonWeb.HttpProvider(process.env.NEXT_PUBLIC_TON_ENDPOINT, {
      apiKey: process.env.NEXT_PUBLIC_TON_ENDPOINT_APIKEY
    }));
  }
  return new TonWeb();
}

export const useJettonTransfer = (): UseJettonTransfer => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [lastTxHash, setLastTxHash] = useState<string | null>(null);

  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const wallet = useTonWallet();
  const connected = !!wallet;

  const buildTransferMessage = useCallback((params: {
    queryId: number;
    amount: bigint;
    destination: Address;
    responseDestination: Address;
    customPayload?: Cell | null;
    forwardAmount: bigint;
    forwardPayload?: Cell | null;
  }): Cell => {
    return beginCell()
    .storeUint(0xf8a7ea5, 32)
    .storeUint(params.queryId, 64)
    .storeCoins(params.amount)
    .storeAddress(params.destination)
    .storeAddress(params.responseDestination)
    .storeMaybeRef(params.customPayload)
    .storeCoins(params.forwardAmount)
    .storeMaybeRef(params.forwardPayload)
    .endCell();
  }, []);

  const transfer = useCallback(async (params: TransferParams): Promise<TransferResult> => {
    try {
      setError(null);
      setIsPending(true);

      if (!tonConnectUI.connected) {
        console.error('Not connected wallet');
        return;
      }

      const tonClient = await initializeClient('mainnet');
      const jettonMaster = Address.parse(params.jettonMasterAddress);
      const destination = Address.parse(params.toAddress);
      const jettonWalletAddress = await getJettonWalletAddress(tonClient,
       wallet.account.address,
       params.jettonMasterAddress);
      console.log(jettonWalletAddress);
      const decimals = params.decimals ?? 9;
      const amount = BigInt(Math.floor(parseFloat(params.amount) * Math.pow(10, decimals)));
      const message = buildTransferMessage({
        queryId: Date.now(),
        amount,
        destination,
        responseDestination: Address.parse(wallet.account.address),
        forwardPayload: params.memo ? beginCell().storeUint(0, 32).storeStringTail(params.memo).endCell() : null,
        // customPayload: params.memo ? beginCell().storeUint(0, 32).storeStringTail(params.memo).endCell() : null,
        forwardAmount: toNano('0'),
      });

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: jettonWalletAddress,
            amount: toNano("0.05").toString(),
            payload: message.toBoc().toString("base64"),
          }
        ]
      };

      const result = await tonConnectUI.sendTransaction(transaction);
      const hash = Cell.fromBase64(result.boc).hash().toString('hex');

      setLastTxHash(hash);

      return {
        success: true,
        txHash: hash,
      };

    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown error occurred';
      setError(errorMessage);
      return {
        success: false,
        error: errorMessage,
      };
    } finally {
      setIsPending(false);
    }
  }, [connected, wallet, buildTransferMessage]);

  return {
    transfer,
    isPending,
    error,
    lastTxHash,
  };
};

export default useJettonTransfer;
