import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';

interface PotionItem {
  potionId: string;
  payId?: string;
  name: string;
  describe?: string;
  star?: number;
  usdt?: string;
  haveCount?: string;
  itemCount?: string;
  label?: string;
}

type PotionMap = {
  [key: string]: PotionItem[];
};

interface PotionResponse {
  data: {
    map: PotionMap;
  };
  status: {
    timestamp: string;
    code: string;
    message: string;
    elapsed: string;
  };
}

interface StatusResponse {
  status: {
    timestamp: string;
    code: string;
    message: string;
    elapsed: string;
  };
}

interface PotionPina {
  level: number;
  name: string;
  prob: number;
  serverFi: string;
  price: number;
}

interface PotionDetail {
  price: number;
  pinaRareProb: number;
}

// get potion
export const fetchGetPotionPackages = () => {
  return request<PotionResponse['data']>({
    url: '/api/mini-api/game/game-potion-packages',
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

// use potion
export const fetchUpdatePotion = (data: { potionId: string|number }) => {
  return request<StatusResponse>({
    url: '/api/mini-api/game/update-potion',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export const usePotionPackages = () => {
  return useQuery({
    queryKey: ['potion-packages'],
    queryFn: () => fetchGetPotionPackages(),
    staleTime: 0,
  });
};

interface PotionDetailResponse {
  potionDetail: PotionDetail;
  potionPinas: PotionPina[];
  xprob: number;
}

export const fetchPotionDetail = (data: { potionId: string | number }) => {
  return request<PotionDetailResponse>({
    url: '/api/mini-api/game/game-potion-detail',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
}

export type {
  PotionItem,
  PotionResponse,
  StatusResponse,
  PotionMap,
  PotionPina,
  PotionDetail,
  PotionDetailResponse
};
