import { cn } from '@/lib/utils';

const difficultyLevelsMap = {
  '1': { label: 'Simple', className: 'text-[#87CEFA] bg-[#87CEFA]/[.2]' },
  '2': { label: 'Primary', className: 'text-[#E8B86D] bg-[#E8B86D]/[.2]' },
  '3': { label: 'Easy', className: 'text-[#7FC272] bg-[#7FC272]/[.2]' },
  '4': { label: 'Medium', className: 'text-[#E99C00] bg-[#E99C00]/[.2]' },
  '5': { label: 'Intense', className: 'text-[#C333EA] bg-[#C333EA]/[.2]' },
  '6': { label: 'Hellish', className: 'text-[#EA3340] bg-[#EA3340]/[.2]' },
};
const DifficultyDisplay = ({
  difficultyLevel,
  showLevel,
}: {
  difficultyLevel: string;
  showLevel?: boolean;
}) => {
  const difficulty = difficultyLevelsMap[difficultyLevel];
    if (!difficulty) {
    return <div className="text-gray-400">Unknown Difficulty</div>;
  }
  const label = showLevel ? `Level ${difficultyLevel}` : difficulty.label;
  return (
    <div
      className={cn(difficulty.className, 'px-[4px] py-[2px] rounded-[6px]')}
    >
      {label}
    </div>
  );
};

export { DifficultyDisplay, difficultyLevelsMap };
