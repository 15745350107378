export const linkReg = /^(https|data):+/;

export const randomString: (len: number) => string = (len) => {
  const pool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let res = '';
  while (len > 0) {
    res += pool[Math.floor(pool.length * Math.random())];
    len--;
  }
  return res;
};

export function getExpiryTimestamp(initTime) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + initTime);
  return time;
}

export const sleep: (timeout: number) => Promise<void> = (timeout) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};
 
 
// o(n) 时间复杂度的洗牌算法
export const fastShuffle: <T = any>(arr: T[]) => T[] = (arr) => {
  const res = arr.slice();
  for (let i = 0; i < res.length; i++) {
    const idx = (Math.random() * res.length) >> 0;
    [res[i], res[idx]] = [res[idx], res[i]];
  }
  return res;
};

export function getItemCount(item: any): string {
  if (typeof item === 'string' || typeof item === 'number') {
    return String(item);
  }
  if (item && typeof item === 'object' && 'haveCount' in item) {
    return String(item.haveCount);
  }
  return '0';
}