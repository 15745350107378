import {
  Account,
  TonProofItemReplySuccess
} from "@tonconnect/ui-react";
import request from '@/http/request';

export const postSetAddress = (proof: TonProofItemReplySuccess['proof'], account: Account, walletName: string) => {
  return request({
    url: '/api/mini-api/payment/set-address',
    data: {
      address: account.address,
      network: account.chain,
      public_key: account.publicKey,
      proof: {
        ...proof,
        state_init: account.walletStateInit,
      },
      walletName,
    },
    method: 'post',
  });
}

export const getPaymentLink = (data: { id: string }) => {
  return request({
    url: '/api/mini-api/payment/create-pay-link',
    data,
    method: 'post',
  }).then(res => res.data);
}
