export interface Item {
  key: string;
  title: string;
  iconName: string;
  action: string;
  desc?: string;
}
// 道具id CELLS:1 UNDO:2 HINT:3 SHUFFLE:4 REVIVE:5 Ticket:7
export const Items = {
  ticket: {
    key: 7,
    title: 'Ticket',
    iconName: 'ticket',
    action: 'both',
    desc: '',
  },
  hint: {
    key: 3,
    title: 'Hint',
    iconName: 'hint',
    action: 'share',
    desc: 'Automatically clears three random tiles',
  },
  undo: {
    key: 2,
    title: 'Undo',
    iconName: 'undo',
    action: 'share',
    desc: 'Revert your last move',
  },
  shuffle: {
    key: 4,
    title: 'Shuffle',
    iconName: 'shuffle',
    action: 'share',
    desc: 'Mixes up the remaining tiles',
  },
  cell: {
    key: 1,
    title: 'Cell',
    iconName: 'cell',
    action: 'share',
    desc: 'Add an extra slot to expand the stack',
  },
  revive: {
    key: 5,
    title: 'Revive',
    iconName: 'revive',
    action: 'share',
    desc: 'Keep playing after game over',
  },
};

export const GameItemsArray = [
  '',
  'cells',
  'undo',
  'hint',
  'shuffle',
  'revive',
];
