const formatNumber = (
  value: number | string,
  options: {
    precision?: number;
  } = {},
) => {
  return Number(value).toFixed(options.precision ?? 8);
};

export default formatNumber;
