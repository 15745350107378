import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { gameItemDTOListAtom } from '@/store/userInfo';
import { GameItemDTOList } from '@/apis/game';

interface GameItemsMap {
  [key: string]: GameItemDTOList | undefined;
}

const useGameItemsMap = () => {
  const gameItemDTOList = useAtomValue(gameItemDTOListAtom);

  const gameItemsMap: GameItemsMap = useMemo(() => {
    const map: GameItemsMap = {};
    gameItemDTOList.forEach((item) => {
      map[item.gameItemName] = {
        ...item,
      };
    });
    return map;
  }, [gameItemDTOList]);

  return gameItemsMap;
};

export default useGameItemsMap;
