// hooks/useUserStatus.js
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import {
  fetchUserStatus,
  type GameItemDTOList,
  UserModel,
  UserGameStatusDTO,
} from '@/apis/login';
import {
  userInfoAtom,
  userGameStatus,
  gameItemDTOListAtom,
} from '@/store/userInfo';

const useUserStatus = () => {
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [gameStatus, setUserGameStatus] = useAtom(userGameStatus);
  const [gameItemDTOList, setGameItemDTOList] = useAtom(gameItemDTOListAtom);

  const {
    mutate: refetchUserStatus,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: () => {
      return fetchUserStatus();
    },
    onSuccess: ({ data }) => {
      setGameItemDTOList(data.gameItemDTOList);
      setUserInfo({
        ...data.userModel,
        claimEasterEgg: data.claimEasterEgg,
      });
      setUserGameStatus(data.userGameStatusDTO);
    },
    onError: (error) => {
      console.error('Failed to fetch user status:', error);
    },
  });

  const updateAllUserStatus = (data: {
    userModel: UserModel;
    userGameStatusDTO: UserGameStatusDTO;
    gameItemDTOList: GameItemDTOList[];
  }) => {
    setGameItemDTOList(data.gameItemDTOList);
    setUserInfo(data.userModel);
    setUserGameStatus(data.userGameStatusDTO);
  };

  return {
    data: {
      userInfo,
      gameStatus,
      gameItemDTOList,
    },
    refetchUserStatus,
    updateAllUserStatus,
    isError,
    error,
    isPending,
  };
};

export default useUserStatus;
