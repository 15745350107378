import { formatCurrency } from '@coingecko/cryptoformat';

export function formatMoney(value: any, decimal = 2) {
  try {
    if (value === undefined || value === null) return '-';
    if (isNaN(value)) return '-';
    return formatCurrency(Number(value), 'usd', 'en', true, {
      decimalPlaces: decimal,
    });
  } catch (e) {
    console.log('🍎🍎🍎formatMoney error value:', value);
    throw new Error(e);
  }
}

export default formatMoney;
