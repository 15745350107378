import { useState, useEffect } from 'react';

export const useEasterEggPassCountdown = (expireTime: string) => {
  const [countdown, setCountdown] = useState('');
  const [countdownStr, setCountdownStr] = useState('');

  useEffect(() => {
    const calculateCountdown = () => {
      const nowUTC = Date.now();
      const expireUTC = Date.parse(expireTime + 'Z');
      const diff = expireUTC - nowUTC;

      if (diff <= 0) {
        setCountdown('00:00:00');
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const sec = Math.floor(((diff % (1000 * 60 * 60)) % (1000 * 60)) / 1000);

      setCountdown(
        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`,
      );

      setCountdownStr(`${hours}h ${minutes}m ${sec}s`);
    };

    calculateCountdown();
    const timer = setInterval(calculateCountdown, 1000);
    return () => clearInterval(timer);
  }, [expireTime]);

  return {
    countdown,
    countdownStr,
    setCountdown,
  };
};
